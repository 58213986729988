module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-offline@6.13.2_gatsby@5.13.7_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@4.9.5_webpack-hot-middleware@2.26.1/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
